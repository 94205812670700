
.center-header{
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-header{
  width: 1200px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 25px 0;

  .logo{
    width: 20%;
    padding-left: 20px;
  }

  .menu-links{
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 20px;

    ul {
      padding:0px;
      margin:0px;
      list-style:none;
    }

    ul li {
      display: inline-block;
      padding-right: 4px;
    }

    a{
      color: #155859;
      font-size: 15px;
      font-weight: 100;

      &:hover,
      &:active{
        color: #FA5064;
        font-weight: 400;
        font-family: 'founders' !important;
      }
    }
  }

  .btn-login{
    display: block;

    padding-right: 20px;

    .topbar-btn-login{
      background: #ffffff;
      border: 1px solid #FA5064;
      // padding: 8px 45px;
      border-radius: 12px;
      color: #FA5064;
      font-size: 15px;
      font-weight: 100;
      padding-left: 45px;
      padding-right: 45px;

      &:hover{
       background: #FA5064;
       color: #ffffff;
      }
    }
  }

  .btn-login-mobile{
    padding-right: 20px;
    display:none;
  }

  // @media (max-width: 900px){
  //   .menu-links{
  //     display: none;
  //   }
  //   .btn-login{
  //     display: none;
  //   }
  //   .btn-login-mobile{
  //     display: flex;
  //   }
  // }

  @media (max-width: 900px){
    body{
      overflow: hidden;
    }
    .menu-links{
      display: none;
    }
    .btn-login{
      display: none;
    }
    .btn-login-mobile{
      display: flex;
    }
  
    .checkbtn {
      display: block;
    }
    ul{
      z-index: 1000;
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #ffffff;
      top: 80px;
      left: -100%;
      text-align: left;
      transition: all .5s;
      padding-left: 20px;
  
    }
    nav ul li{
      display: block;
      margin: 50px 0;
      line-height: 30px;
    }
    nav ul li a {
      font-size: 20px;
    }
    a:hover, a.active {
      background: none;
      color: #FA5064;
    }
    #check:checked ~ ul {
      left: 0;
    }
  
  }

}


