.result-info{
  .text{
    color: #4A5757;

    .title{
      font-weight: 300;

    }
  }
}

.container-result{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 270px;
  background: #eef4f2;
  border-radius: 10px;
  margin-top: 30px;

  .image{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .infos{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    p{
      color: #4A5757;
      line-height: 1.8;
      font-size: 15px;
    }

    .total{
      color: #071E1F;
      margin-bottom: 30px;
      font-size: 38px;
      font-weight: 300;
    }

    span{
      margin-top: 20px;
      color: #155859;
      font-weight: 400;
      text-decoration:underline;
    }

    a{
      font-size: 15px;
      margin-top: 20px;
      color: #155859;
      font-weight: 400;
      text-decoration:underline;
    }

  }
}

@media(max-width:899px){

  .container-result{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  
    .image{
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .infos{
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      p{
        color: #4A5757;
        line-height: 1.8;
        font-size: 15px;
      }
  
      .total{
        color: #071E1F;
        margin-bottom: 30px;
        font-size: 38px;
        font-weight: 300;
      }
  
      span{
        margin-top: 20px;
        color: #155859;
        font-weight: 400;
        text-decoration:underline;
      }
  
      a{
        font-size: 15px;
        margin-top: 20px;
        color: #155859;
        font-weight: 400;
        text-decoration:underline;
      }
  
    }
  }


}
