
.center-footer{
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #155859;
    margin-top: 60px;
    bottom: 0;
  }
  .container-footer{
    width: 1200px !important;
    max-width: 1200px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 40px 30px 40px;
    bottom: 0;
  
    @media (min-width: 700px){
      flex-direction: row;
    }
  
    .logo{
      padding-top: 30px;
    }
  
    .site-map{
      padding-top: 30px;
    }
  
    .register{
      padding-top: 30px;
    }
  
    .contact{
      padding-top: 30px;
    }
  
    .social{
      padding-top: 30px;
    }

    .title{
        margin-bottom: 15px;
        color: #ffffff;
      }
      
      .container-social-icons{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      .colorlink {
        color: #D2D9D9;
      }
  }
  
  // .pt20{
  //   margin-bottom: 20px !important;
  // }
  

  