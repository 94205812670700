.form-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;

    .input-select{
    width: 55%;
    margin-top: 30px;

    input{
        width: 100%;
        height: 50px;
        border-radius: 10px;
        border: 1px solid #DFDFDF;
        color: #4A5757;
        font-size: 14px;
        font-weight: 100;
    }
    
    .label {
        /* Center the content horizontally */
        align-items: center;
        display: inline-flex;
        
        /* Cursor */
        cursor: pointer;
    }
    
    }

    .container-label{
    display: block;
    position: relative;
    padding-left: 20px;
    font-size: 14px;
    color: #424242;
    font-weight: 100;

        a{
            cursor: pointer;
            color: #424242;
            text-decoration: underline;
        }
    
    }
    
    .container-label input{
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    
    }
    
    .checkmark{
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: white;
    border: 1px solid gray;
    border-radius: 4px;
    }
    .container-label input:checked ~ .checkmark{
    background-color: #155859;
    border: none;
    }
    
    .checkmark::after{
    display: none;
    content: '';
    position: absolute;
    }
    
    .container-label .checkmark::after{
    content: '';
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    transform: rotate(45deg);
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    
    }
    
    .container-label input:checked ~ .checkmark::after{
    display: block;
    }

    .button-link{
        background: none;
        border: none;
        text-decoration: underline;
    }
    
}

@media(max-width: 700px){
    .form-container{
        .input-select{
            width: 100%;
        }
    }        

}
