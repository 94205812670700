@font-face {
  font-family: 'founders';
  src: url('./assets/fonts/FoundersGrotesk-Regular.otf');
}
@font-face {
  font-family: 'founders';
  src: url('./assets/fonts/FoundersGrotesk-Light.otf');
  font-weight: 100;
  descent-override: 10%;
}
@font-face {
  font-family: 'founders';
  src: url('./assets/fonts/FoundersGrotesk-Medium.otf');
  font-weight: 300;
  descent-override: 10%;
}
@font-face {
  font-family: 'founders';
  src: url('./assets/fonts/FoundersGrotesk-Semibold.otf');
  font-weight: 400;
  descent-override: 10%;
}
@font-face {
  font-family: 'founders';
  src: url('./assets/fonts/FoundersGroteskXCond-Bold.otf');
  font-weight: 600;
  descent-override: 10%;
}

body {
  margin: 0;
  /* font-family: "Roboto", sans-serif; */
  font-family: 'founders' !important;
  font-weight: 100;
  color: #7e7e7e;
  background-color: #ffffff;

}

.container-progress{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

}

.progress{
  width: 100%;
  font-size: 0.8rem;
  font-weight: bold;
}
.card-img-top{
  width: 10rem;
}

.form-check-label {
  padding: 20px;
}

input[type="radio"]:checked+label {
  border: 2px solid #efbb20;
  background-color: #efbb2033;
}

.progress{
  height: 10px;
}

.progress-bar {
  background-color: #FA5064;
  transition: width .6s ease;
  border-radius: 20px;
  height: 10px;
}

span{
  font-size: 0.9rem;
  color: #7E9293;
  // margin-left: 25px;
}

.container-flex{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .contents{
    width: 60%;
    padding-top: 60px;

    .header{
      width: 100%;
      span{
        color: #071E1F;
        margin-bottom: 30px;
        font-size: 38px;
        font-weight: 400;
      }
      p{
        color:#4A5757;
        margin-bottom: 30px;
        font-size: 16px;
      }
    }

    .button-options{
      width: 100%;
      margin-bottom: 30px;

      ul li{
  
      }

      .inactiveleft{
        padding: 5px 40px 5px 40px;
        border-radius: 30px;
        background-color: #EFF2F2;
        color: #7E9293;
      }
      
      .inactiveright{
        padding: 5px 40px 5px 40px;
        border-radius: 0px 30px 30px 0px;
        background-color: #EFF2F2;
        color: #7E9293;
      }


      .nav-pills .nav-link.active{
        // border-radius: 30px!important;
        background-color: #155859!important;
        color: #fff;
      }

      .nav-pills .nav-link{
        padding: auto!important;
      }
    }
  }

}


.check-input-term{
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.sub-title-avaliacao{
  color: #071E1F !important;
  font-size: 22px !important;
  font-weight: 300 !important;
}

.form-Evaluation{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  .input-select{
    width: 48%;
    margin-top: 30px;

    select{
      height: 50px;
      border-radius: 10px;
      border: 1px solid #DFDFDF;
      color: #4A5757;
      font-weight: 100;
    }
    input{
      width: 100%;
      height: 50px;
      border-radius: 10px;
      border: 1px solid #DFDFDF;
      color: #4A5757;
      font-weight: 100;
    }
  }

  .input-stepper{
    margin-top: 30px;
    width: 22%;
    span{
      font-size: 12px;
    }
    .stepper {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #DFDFDF;
      border-radius: 10px;
      height: 50px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 15px;
      font-weight: 100;

    }

    .stepper__button {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #155859;
      color: #155859;
      font-size: 15px;
      cursor: pointer;
      user-select: none;
    }

    .stepper__content {
      flex: 1;
    }

    .stepper__input {
      height: 100%;
      width: 100%;
      border: none;
      text-align: center;
      font-weight: 100;
      padding-top: 10px;
    }
  }
  
}

.footer-button{
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #EFF2F2;
  margin-top: 30px;

  .container-footer-action{
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button{
      padding: 10px 30px 8px 30px;
      background: #155859;
      color: #ffffff;
      border-radius: 10px;
      border: none;
      font-size: 14px;
      font-weight: 100;
    }
    .button-back{
      background: none;
      border: none;
      margin-right: 15px;
      color: #7E9293;
    }

  }
}

.nav-link {
  color: #7e7e7e;;
}

.nav-link:hover {
  color: #FA5064;
}

@media(max-width: 935px){
  .form-Evaluation{
    .input-stepper{
      width: 48%;
    }
  }  
}

@media(max-width: 700px){
  .form-Evaluation{
    .input-select{
      width: 100%;
    }
  }

  .container-flex{
    .contents{
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }  

}
