.container-cards{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  gap: 5px;
  flex-wrap: wrap;

  .card-layouts{
    width: 32%;
    height: 100%;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #D2D9D9;
    border-radius: 8px;
    flex-wrap: wrap;

    .title{
      color:#155859;
      font-size: 14px;
      font-weight: 600;

    }

    input{
      width: 20px;
      height: 20px;
      margin-top: 25px;
      margin-left: 90%;
    }

    input[type="radio"] {
      background-color: #ffffff;
      border-radius: 50%;
      border: 1px solid #155859;
      cursor: pointer;
      height: 18px;
      position: relative;
      width:18px;
    }
    input[type="radio"]:after {
        border-radius:50%;
        content: '';
        display: block;
        height: 18px;
        width: 18px;
        border: none;
    }
    input[type="radio"]:checked:after {
        background-color: #155859;
        border: none;
        color: #ffffff;
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="white" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg>');
    }

    .image-project{
      // object-fit: cover;
      margin-top: 20px;
      background: #7E9293;
      width: 100%;
      height: 40%;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;

      }

      .container-photos{
        width: 100%;
        height: 100%;
        img{
          object-fit: cover;
          width: 100%;
          height: 180px;
  
        }

        // object-fit: cover;
      }

    }

    .card-layouts-infos{
      width: 100%;
      display: flex;
      margin-top: 30px;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;

      .title-card-immobile{
          font-size: 15px;
          font-weight: 300;
          color: #155859;
      }

      .limit-characters{
        color: #7E9293;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
  }
  
}


.slick-prev {
  left: -1px;
  z-index: 10;
}
.slick-next {
  right: -1px;
  z-index: 10;
}

@media(max-width: 1200px){

  .container-cards{
    .card-layouts{
      width: 100%;
      margin-bottom: 30px;
    }

    .image-project{
      height: 350px;
      img{
        object-fit: cover;
        width: 100%;
        height: 100%;

      }

      .container-photos{
        width: 100%;
        height: 100%;
        img{
          height: 300px !important;
  
        }

      }

    }
  }    

}